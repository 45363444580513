import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 16 16">
    <path
      fill={color}
      d="M15,7.5H8.5V1c0-0.3-0.2-0.5-0.5-0.5S7.5,0.7,7.5,1v6.5H1C0.7,7.5,0.5,7.7,0.5,8S0.7,8.5,1,8.5h6.5V15
	c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V8.5H15c0.3,0,0.5-0.2,0.5-0.5S15.3,7.5,15,7.5z"
    />
  </svg>
);
